<template>
  <v-card :color="theme ? '' : '#1748CF'">
        <div class="rounded" :class="{'badge-dark': theme }">
          <v-list-item class="white--text">
            <v-list-item-avatar>
              <v-img
              v-if="badge.node.badge.badgeIconImage"
              sizes="50"
              :src="debugMode ? `${$staticUrl}/${badge.node.badge.badgeIconImage}` : badge.node.badge.badgeIconImage"
              >
              </v-img>
              <v-icon color="#C4C4C4" size="50"  v-else>
                mdi-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
            </v-list-item-content>

            <v-list-item-content>
              <v-list-item-title class="text-uppercase">
              {{ badge.node.badge.badgeName }}
              </v-list-item-title>
            </v-list-item-content>

            <v-list-item-content>
              <v-list-item-title>
                {{ formatUTCTime(badge.node.dateAwarded) }}
              </v-list-item-title>
            </v-list-item-content>

      </v-list-item>
        </div>
  </v-card>
</template>

<script>
import colorMxn from '@/mixins/color_mixin';
import timeMxn from '@/mixins/time_mxn';
import { DEBUG } from '@/settings/';

export default {
  name: 'badgeComponent',
  mixins: [colorMxn, timeMxn],
  props: ['badge'],
  data() {
    return {}
  },
  computed: {
    debugMode() {
      return DEBUG;
    }
  },
}
</script>
