import moment from 'moment';

const timezoneMxn = {
  methods: {
    convertToLocalTime(date) {
      const local = moment.utc(date).local().format();
      return local;
    },
    formatUTCTime(row) {
      const localTime = this.convertToLocalTime(row.date_created);
      const formated = moment(localTime).format('Do MMM YYYY');
      return formated;
    },
    checkTime(date) {
      const currentTime = moment();
      const timeStore = moment(date);

      return currentTime.diff(timeStore, 'h');
    },
  },
};

export default timezoneMxn;
